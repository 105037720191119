import React from 'react'
import Image from 'gatsby-image'
import Icon from '../modules/icon';

export default ({posts, additionalClass, style}) => {
  const data = posts.sort(function(x, y){
    return y.node.timestamp - x.node.timestamp;
  })
  
  return (
    <section className={`component gallery ${additionalClass}`} style={style}>
      <div className="wrapper">
        {
          data.map((item, i) => {
            return (
              <div className="brick" key={i}>
                <a href={`https://www.instagram.com/p/${item.node.id}/`} target="_blank" rel="noopener noreferrer">
                  {item.node.localFile? <Image fluid={item.node.localFile.childImageSharp.fluid} />  : <div></div>}
                  {item.node.mediaType === 'GraphVideo' ?
                    <div className="videoWrapper"><div><Icon name="play" width="40" height="40" /></div></div>
                    : null
                  }
                  <div className="details">
                    <div>
                      <Icon name="heart" width="20" height="20" />
                      <span>
                        {item.node.likes || 0}
                      </span>
                    </div>
                    <div>
                      <Icon name="comment" width="20" height="20" />
                      <span>{item.node.comments || 0}</span>
                    </div>
                  </div>
                </a>
              </div>
            )
          }) 
        }
      </div>
    </section>
  )
}
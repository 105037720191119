import React from "react"
import { graphql } from "gatsby"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import BigHeader from "../components/bigHeader"
// import GeneralContent from "../components/generalContent"
import QuickSelect from "../components/quickSelect"
import Gallery from '../components/gallery'

const Blog = ({ data }) => {
  const content = data.allContentfulLieblingsplatzBlog.nodes[0]
  
  return (
    <Layout theme="lieblingsplatz">
      <SEO title={content.title} />
      <BigHeader 
        image={content.headerImage.fluid} 
        alt={content.headerImage.description} 
        additionalClass="home"
        title={content.title}
        subTitle={content.subTitle}
      />
      <QuickSelect root={true} />
      {/* <GeneralContent
        title={content.title}
        content={content.content ? content.content.json : null}
        textCentered={false}
      /> */}
      <Gallery 
        additionalClass="pt-2"
        posts={data.allInstaNode.edges}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
query blogQuery {
  allContentfulLieblingsplatzBlog(filter: {type: {eq: "Blog"}}) {
    nodes {
      content {
        content
      }
      id
      title
      subTitle
      headerImage {
        fluid(maxWidth: 1600 quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
        description
      }
    }
  }

  allInstaNode {
    edges {
      node {
        id
        likes
        comments
        mediaType
        preview
        original
        timestamp
        caption
        localFile {
          childImageSharp {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
        }
        # Only available with the public api scraper
        thumbnails {
          src
          config_width
          config_height
        }
        dimensions {
          height
          width
        }
      }
    }
  }
}
`

export default Blog
